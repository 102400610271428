import { DateTime } from 'luxon';
import styles from './styles.module.scss';
import { INotice } from '../../stores/notice';

interface INoticesProps {
	notices: INotice[];
}
function Notices(props: INoticesProps) {
	const { notices } = props;

	function classIcon(notice: INotice) {
		if (notice.type == 'success') return '✅';
		return '⛔️';
	}
	function getTitle(notice: INotice) {
		if (notice.title) return notice.title;
		return notice.type.charAt(0).toUpperCase() + notice.type.slice(1);
	}
	function dateTime(time: Date) {
		return DateTime.fromJSDate(time).toFormat('M/d @ h:mma').toLowerCase();
	}

	return (
		<div className={styles.noticeContainer}>
			{notices.map(notice => {
				return (
					<div
						key={notice.uuid}
						className="notice"
						data-key="notice_i"
					>
						<div
							className="toast show"
							role="alert"
							aria-live="assertive"
							aria-atomic="true"
						>
							<div className="toast-header">
								<p className="me-1">{classIcon(notice)}</p>
								<strong className="me-auto">
									{getTitle(notice)}
								</strong>
								<small className="ms-4">
									{dateTime(notice.time)}
								</small>
								{/* <button onClickk={() => remove(notice_i)} type="button" className="me-1 mb-1 btn-close" data-bs-dismiss="toast" aria-label="Close" /> */}
							</div>
							<div className="toast-body">{notice.msg}</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default Notices;
