import { useNavigate } from 'react-router';
import styles from './styles.module.scss';
import { useAppStore } from '../../lib/stores/appMain';
import { useCallback, useMemo, useState } from 'react';
import LogoutModal from '../../lib/components/Modal/Logout';

function Login() {
	const navigate = useNavigate();
	const { APP } = useAppStore(state => state);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	function handleSubmit() {
		navigate('/oauth/auth');
	}

	const handleLoggedinUserSubmit = useCallback(() => {
		navigate(`/`);
	}, [navigate]);

	const renderLoggedInCta = useMemo(() => {
		if (!APP.Auth) return null;

		return (
			<>
				<p className="text-center">OR</p>
				<button
					onClick={handleLoggedinUserSubmit}
					type="submit"
					className="w-100 border rounded-pill d-flex align-items-center btn d-flex items-center justify-content-center gap-2 p-2 px-3 text-white rounded"
				>
					<p className="m-0">
						Continue With{' '}
						<span className="fw-bold">{APP.User?.Name}</span>
					</p>
				</button>
			</>
		);
	}, [APP.Auth, APP.User?.Name, handleLoggedinUserSubmit]);

	const renderLogoutCta = useMemo(
		function renderLogoutCta() {
			if (!APP.Auth) return null;

			return (
				<button
					className="btn end-0 m-3 position-absolute text-start text-white top-0"
					onClick={() => setShowConfirmationModal(true)}
				>
					<i className="bi bi-box-arrow-right me-2" />
					Logout
				</button>
			);
		},
		[APP.Auth],
	);

	return (
		<div className="d-flex align-items-center justify-content-center h-100 flex-column gap-5 w-100 position-relative">
			{renderLogoutCta}
			<p className="fs-3 fw-bold text-center">
				Welcome to <br /> Admin Dashboard
			</p>
			<div className="d-flex align-item-center justify-content-ceter gap-3 flex-column w-25">
				<button
					onClick={handleSubmit}
					type="submit"
					className={`${styles.submitCta} rounded-pill d-flex align-items-center btn bg-primary d-flex items-center justify-content-center gap-2 p-2 px-3 text-white rounded`}
				>
					<p className="m-0">
						<i className="bi bi-instagram me-1"></i> Login with
						Instagram
					</p>
				</button>
				{renderLoggedInCta}
				<LogoutModal
					isModalOpen={showConfirmationModal}
					closeModal={() => setShowConfirmationModal(false)}
				/>
			</div>
		</div>
	);
}

export default Login;
