import { useCallback, useEffect, useMemo, useState } from 'react';
import Header from '../../lib/components/Header';
import {
	getAllPostSubmissions,
	getUserInfo,
	getVideoInfo,
	IPostInfo,
	IProductStatus,
	IUpdatePostParams,
	IUserInfo,
	updatePostStatus,
} from '../../lib/utils/apis';
import styles from './styles.module.scss';
import { Modal } from 'react-bootstrap';

function Submissions() {
	const [posts, setPosts] = useState<IPostInfo[]>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [modalType, setModalType] = useState('');
	// to be used in modal
	const [postInfo, setPostInfo] = useState<IPostInfo | undefined>();
	const [videoUrl, setVideoUrl] = useState<string | undefined>();
	const [userInfo, setUserInfo] = useState<IUserInfo | undefined>();

	const [couponCode, setCouponCode] = useState('');

	const tableHeader = [
		'#',
		'Product Image',
		'Product Name',
		'Caption',
		'Status',
		'Actions',
	];

	async function fetchAllSubmissions() {
		try {
			setLoading(true);
			const data = await getAllPostSubmissions();
			const products = data?.posts || [];
			setPosts(products);
		} catch (e) {
			console.error(e);
			setError(true);
		} finally {
			setLoading(false);
		}
	}

	async function fetchVideoDetails(id: string) {
		const data = await getVideoInfo(id);
		const videoUrl = data?.url || '';
		setVideoUrl(videoUrl);
	}

	async function fetchUserDetails(id: string) {
		const data = await getUserInfo(id);
		setUserInfo(data);
	}

	function handleShowDetailsClick(post: IPostInfo) {
		setShowModal(true);
		setModalType('details');
		setPostInfo(post);
	}

	// TODO Uncomment this when we have better UI for approve and decline
	// function handleApprovedClick(post: IPostInfo) {
	// 	setShowModal(true);
	// 	setModalType('approved');
	// 	setPostInfo(post);
	// }

	// function handleDeclineClick(post: IPostInfo) {
	// 	setShowModal(true);
	// 	setModalType('declined');
	// 	setPostInfo(post);
	// }

	function handleCloseModal() {
		setShowModal(false);
		setPostInfo(undefined);
		setModalType('');
		setCouponCode('');
		setUserInfo(undefined);
	}

	const handleApprovePost = useCallback(
		async function handleApprovePost() {
			const params: IUpdatePostParams = {
				postId: postInfo?._id || '',
				status: IProductStatus.Approved,
				couponCode: couponCode,
			};
			await updatePostStatus(params);
			handleCloseModal();
			fetchAllSubmissions();
			return;
		},
		[postInfo, couponCode],
	);

	const handleDeclinePost = useCallback(
		async function handleDeclinePost() {
			const params: IUpdatePostParams = {
				postId: postInfo?._id || '',
				status: IProductStatus.Declined,
			};

			await updatePostStatus(params);
			handleCloseModal();
			fetchAllSubmissions();
			return;
		},
		[postInfo],
	);

	const handleCouponCodeChangeText = useCallback(
		function handleCouponCodeChangeText(text: string) {
			setCouponCode(text);
		},
		[],
	);

	const renderModal = useMemo(
		function renderModal() {
			if (modalType === 'details' && postInfo && userInfo) {
				let statusStyles = 'text-body';
				let showApprovalViews = false;
				if (postInfo.status === 'Approved') {
					statusStyles = `${styles.approved} border-0 text-white`;
				} else if (postInfo.status === 'Declined') {
					statusStyles = `${styles.declined} border-0 text-white`;
				} else {
					showApprovalViews = true;
				}

				const rewardsArray: string[] = [];
				postInfo.rewards.forEach(reward => {
					if (reward.subtitle) rewardsArray.push(reward.title);
				});

				return (
					<Modal show={showModal} onHide={handleCloseModal}>
						<Modal.Header closeButton>
							<Modal.Title>Details</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div>
								<div className="text-end">
									<p
										className={`${statusStyles} p-1 mt-1 d-inline border rounded px-2`}
									>
										{postInfo.status}
									</p>
								</div>
								<div>
									<div className="mt-2 text-black d-flex justify-content-center">
										<video
											className="mt-1 rounded"
											src={videoUrl}
											height={300}
											controls
										></video>
									</div>
									<div>
										<p className="text-black fw-bold mt-2">
											Caption
										</p>
										<p className="text-black bg-dark-subtle rounded p-2 mt-1">
											{postInfo.caption}
										</p>
									</div>
								</div>
								<div>
									<p className="text-black fw-bold mt-2">
										Product
									</p>
									<div className="d-flex gap-2 align-items-center mt-1">
										<img
											className="rounded"
											src={postInfo?.productImage}
											height={50}
										/>
										<div>
											<p className="text-black fw-bold">
												{postInfo.productName}
											</p>
											<p className="text-black">
												{rewardsArray.join(' + ')}
											</p>
										</div>
									</div>
								</div>
								<div>
									<p className="text-black fw-bold mt-4">
										User
									</p>
									<div className="d-flex gap-2 align-items-center mt-1">
										<img
											className="rounded"
											src={userInfo?.profile_picture_url}
											height={70}
										/>
										<div>
											<p className="text-black bg-dark-subtle rounded p-1 fw-bold">
												{userInfo.username}
											</p>
											<p className="text-black">
												Followers:
												<span className="fw-bold mx-2">
													{userInfo.followers_count}
												</span>
											</p>
										</div>
									</div>
								</div>

								{showApprovalViews && (
									<div>
										<div className="mt-4 text-black mt-4 bg-body-secondary rounded p-3">
											<p className="text-body fw-bold">
												Enter Coupon Code.
											</p>
											<input
												className="mt-1 p-1 rounded border-1"
												value={couponCode}
												type="text"
												onChange={e =>
													handleCouponCodeChangeText(
														e.target.value,
													)
												}
											/>
										</div>
									</div>
								)}
							</div>
						</Modal.Body>
						{showApprovalViews && (
							<Modal.Footer>
								<button
									className={`btn ${styles.declined} text-white`}
									onClick={handleDeclinePost}
								>
									Decline
								</button>
								<button
									disabled={!couponCode}
									className={`btn ${styles.approved} text-white`}
									onClick={handleApprovePost}
								>
									Approve
								</button>
							</Modal.Footer>
						)}
					</Modal>
				);
			}

			if (modalType === 'declined') {
				return (
					<Modal show={showModal} onHide={handleCloseModal}>
						<Modal.Header closeButton>
							<Modal.Title>Confirm</Modal.Title>
						</Modal.Header>
						<Modal.Body>This post will be declined.</Modal.Body>
						<Modal.Footer>
							<button
								className={`outline-0 border-0 p-2 px-3 rounded ${styles.declined} text-white`}
								onClick={handleCloseModal}
							>
								Close
							</button>
							<button
								className={`outline-0 border-0 p-2 px-3 rounded ${styles.approved} text-white`}
								onClick={handleDeclinePost}
							>
								Confirm
							</button>
						</Modal.Footer>
					</Modal>
				);
			}

			if (modalType === 'approved') {
				return (
					<Modal show={showModal} onHide={handleCloseModal}>
						<Modal.Header closeButton>
							<Modal.Title>Confirm</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div>
								<p className="text-body">
									This post will be Approved.
								</p>
								<p className="text-body">Enter Coupon Code.</p>
								<input
									value={couponCode}
									type="text"
									onChange={e =>
										handleCouponCodeChangeText(
											e.target.value,
										)
									}
								/>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<button
								className={`outline-0 border-0 p-2 px-3 rounded ${styles.declined} text-white`}
								onClick={handleCloseModal}
							>
								Close
							</button>
							<button
								disabled={!couponCode}
								className={`outline-0 border-0 p-2 px-3 rounded ${styles.approved} text-white`}
								onClick={handleApprovePost}
							>
								Confirm
							</button>
						</Modal.Footer>
					</Modal>
				);
			}
		},
		[
			userInfo,
			videoUrl,
			modalType,
			showModal,
			couponCode,
			handleCouponCodeChangeText,
			postInfo,
			handleApprovePost,
			handleDeclinePost,
		],
	);

	useEffect(() => {
		fetchAllSubmissions();
	}, []);

	useEffect(() => {
		if (modalType === 'details' && postInfo) {
			fetchVideoDetails(postInfo.uploadId);
			fetchUserDetails(postInfo.userId);
		}
	}, [modalType, postInfo]);

	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center h-100 w-100">
				<p>Loading...</p>
			</div>
		);
	}

	if (error) {
		return (
			<div className="d-flex align-items-center justify-content-center h-100 w-100">
				<p>Some error occurred. Please try again after sometime.</p>
			</div>
		);
	}

	return (
		<>
			<div className="p-3 w-100 overflow-auto">
				<Header text="Submissions" classes="my-3" />
				<div className="rounded overflow-hidden">
					<table className="table rounded">
						<thead className="table-light align-middle">
							<tr>
								{tableHeader.map((item, index) => (
									<th key={index}>{item}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{posts &&
								posts.map((item, index) => {
									let statusStyles;
									if (item.status === 'Approved') {
										statusStyles = `${styles.approved} border-0 text-white`;
									} else if (item.status === 'Declined') {
										statusStyles = `${styles.declined} border-0 text-white`;
									}
									return (
										<tr key={item._id}>
											{/* index */}
											<th className={styles.th}>
												{index + 1}.
											</th>
											{/* image */}
											<td className={styles.td}>
												<img
													className="rounded"
													src={item.productImage}
													height={50}
												/>
											</td>
											{/* product name */}
											<td className={styles.td}>
												{item.productName}
											</td>
											{/* caption */}
											<td className={styles.td}>
												{item.caption}
											</td>
											{/* status */}
											<td className={`${styles.td}`}>
												<span
													className={`p-1 border rounded-pill px-2 ${statusStyles}`}
												>
													{item.status}
												</span>
											</td>
											{/* actions */}
											<td className={`${styles.td} `}>
												<div className="d-flex gap-2 justify-content-center">
													<button
														title="See post"
														className="btn outline-0 border-0 p-0 m-0"
														onClick={() =>
															handleShowDetailsClick(
																item,
															)
														}
													>
														<i className="bi bi-arrow-right-circle-fill" />
													</button>
													{/* TODO Need better UX for approve and decline */}
													{/* <button
														title="Decline"
														disabled={
															item.status !==
															IProductStatus.Submitted
														}
														className="btn outline-0 border-0 p-0 m-0"
														onClick={() =>
															handleDeclineClick(
																item,
															)
														}
													>
														⛔️
													</button>
													<button
														title="Approve"
														disabled={
															item.status !==
															IProductStatus.Submitted
														}
														className="btn outline-0 border-0 p-0 m-0"
														onClick={() =>
															handleApprovedClick(
																item,
															)
														}
													>
														✅
													</button> */}
												</div>
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>
			</div>
			{renderModal}
		</>
	);
}

export default Submissions;
