import { useMemo } from 'react';
import { useAppStore } from '../../lib/stores/appMain';

function Home() {
	const { APP } = useAppStore(state => state);

	const renderBlockedReason = useMemo(
		function renderBlockedReason() {
			if (!APP.User?.Blocked) return null;

			return (
				<div className="align-items-center bg-dark d-flex gap-2 px-2 rounded">
					<i className="bi bi-info-circle-fill fs-5 text-white" />
					<p className="d-inline ">{APP.User?.BlockedReason}</p>
				</div>
			);
		},
		[APP.User?.Blocked, APP.User?.BlockedReason],
	);

	return (
		<div className="d-flex align-items-center justify-content-center h-100 w-100 m-3 flex-column gap-3">
			<img src={APP.User?.Image} height={100} className="rounded-pill" />
			<p className="fs-2 text-center">
				Welcome <br />
				<span className="fw-bold text-primary">{APP.User?.Name}</span>
			</p>
			{renderBlockedReason}
		</div>
	);
}

export default Home;
