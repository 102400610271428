import { useEffect } from 'react';
import { getInstagramAuthUri } from '../../../lib/utils/globals';

function Auth() {
	useEffect(() => {
		const oauthUrl = getInstagramAuthUri();
		window.location.href = oauthUrl;
	}, []);

	return null;
}

export default Auth;
