import { create } from 'zustand';

export interface NoticeWithoutMeta {
	type: 'info' | 'error' | 'warning' | 'success';
	msg: string;
	snooze?: number;
}
export interface INotice extends NoticeWithoutMeta {
	time: Date;
	title?: string;
	uuid: string;
}
interface IUseNotice {
	notices: INotice[];
	updateNotice: (noticeObj: INotice) => void;
	removeNotice: (noticeObj: INotice) => void;
}

export const useNoticeStore = create<IUseNotice>(set => ({
	notices: [],
	updateNotice: noticeObj =>
		set(state => {
			return { notices: [...state.notices, noticeObj] };
		}),
	removeNotice: noticeObj =>
		set(state => {
			return {
				notices: state.notices.filter(
					notice => notice.uuid !== noticeObj.uuid,
				),
			};
		}),
}));

export const addNotice = (notice: NoticeWithoutMeta | string) => {
	const { updateNotice, removeNotice } = useNoticeStore.getState();
	let notice_obj = notice as INotice;
	if (typeof notice == 'string') {
		notice_obj = {
			type: 'info',
			msg: notice,
		} as INotice;
	}
	notice_obj.uuid = generateUUID();
	notice_obj.time = new Date();
	if (notice_obj.snooze) {
		setTimeout(() => {
			//remove the notice with the uuid
			removeNotice(notice_obj);
		}, notice_obj.snooze * 1000);
	}
	updateNotice(notice_obj);
};

export const addError = (
	err: string | Error | NoticeWithoutMeta,
	snooze = 0,
) => {
	let error_obj: NoticeWithoutMeta;
	if (typeof err == 'string') {
		error_obj = {
			type: 'error',
			msg: err,
		};
	} else if (err instanceof Error) {
		error_obj = {
			type: 'error',
			msg: err.message,
		};
	} else {
		console.log(err);
		error_obj = {
			type: 'error',
			msg: 'Unable to display error. Check console',
		};
	}
	if (snooze) error_obj.snooze = snooze;
	addNotice(error_obj);
};

function generateUUID() {
	let uuid = '';
	for (let i = 0; i < 32; i++) {
		if (i === 8 || i === 12 || i === 16 || i === 20) {
			uuid += '-';
		}
		uuid += Math.floor(Math.random() * 16).toString(16);
	}
	return uuid;
}
