import { useAppStore } from '../stores/appMain';
import { addError } from '../stores/notice';
import { serializeResponse } from './globals';

interface IAuthInfo {
	rt_token: string;
	at_token: string;
	at_expiry: number;
	rt_expiry: number;
}

interface IAppSignInInfo extends App.IAuth, App.IUser {}

interface ISignInUSerProps {
	code: string;
	redirectURL: string;
}
// Get userinfo and auth details ✅
export async function signInUser(
	params: ISignInUSerProps,
): Promise<IAppSignInInfo | undefined> {
	interface ISigninInfo extends IAuthInfo {
		_id: string;
		username: string;
		name: string;
		account_type: string;
		profile_picture_url: string;
		agencyKey: string[];
		blocked: boolean;
		blocked_reason: string;
	}
	const { code, redirectURL } = params;
	const body = {
		code,
		user_role: 'businessAdmin',
		redirectURL,
	};
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	try {
		const res = await fetch(
			`${process.env.REACT_APP_ADMIN_URL}/public/signIn`,
			{
				method: 'POST',
				headers,
				body: JSON.stringify(body),
				redirect: 'follow',
			},
		);
		const jsonResp: unknown = await res.json();
		if (!jsonResp || typeof jsonResp !== 'object')
			throw Error('Server error. Not an object. ⛔️');
		if (
			res.status !== 200 &&
			'message' in jsonResp &&
			typeof jsonResp.message === 'string'
		)
			throw Error(jsonResp.message);
		if (
			!('data' in jsonResp) ||
			typeof jsonResp.data !== 'object' ||
			!jsonResp.data
		)
			throw Error('Server error. ⛔️');

		const data = jsonResp.data as ISigninInfo;

		const serializedSignInInfo = serializeResponse<
			IAppSignInInfo,
			ISigninInfo
		>(data, {
			AccessToken: 'at_token',
			RefreshToken: 'rt_token',
			AccessExpires: 'at_expiry',
			RefreshExpires: 'rt_expiry',
			ID: '_id',
			Username: 'username',
			AccountType: 'account_type',
			Name: 'name',
			Image: 'profile_picture_url',
			AgencyKey: 'agencyKey',
			Blocked: 'blocked',
			BlockedReason: 'blocked_reason',
		});
		return serializedSignInInfo;
	} catch (e) {
		// TODO store logs and show toast
		const message = (e as Error).message || 'Unkown error';
		addError(message, 5);
		console.error(message);
	}
}

interface IRewards {
	type: string;
	title: string;
	subtitle: string;
}
export interface IPostInfo {
	_id: string;
	userId: string;
	caption: string;
	uploadId: string;
	rewards: IRewards[];
	productId: string;
	productImage: string;
	productName: string;
	status: 'Submitted' | 'Declined' | 'Approved';
}
interface IPostSubmissionsInfo {
	posts: IPostInfo[];
}
// get all post submissions ✅
export async function getAllPostSubmissions(): Promise<
	IPostSubmissionsInfo | undefined
> {
	try {
		const { APP } = useAppStore.getState();
		const headers = new Headers();
		headers.append('agencyKey', APP.User?.AgencyKey[0] || '');
		headers.append('at_token', APP.Auth?.AccessToken || '');

		const res = await fetch(
			`${process.env.REACT_APP_ADMIN_URL}/secure/post?sort={createdAt:-1}`,
			{
				method: 'GET',
				headers,
			},
		);
		const jsonResp: unknown = await res.json();
		if (!jsonResp || typeof jsonResp !== 'object')
			throw Error('Server error. Not an object. ⛔️');
		if (
			res.status !== 200 &&
			'message' in jsonResp &&
			typeof jsonResp.message === 'string'
		)
			throw Error(jsonResp.message);
		if (
			!('data' in jsonResp) ||
			typeof jsonResp.data !== 'object' ||
			!jsonResp.data
		)
			throw Error('Server error. ⛔️');

		return jsonResp.data as IPostSubmissionsInfo;
	} catch (e) {
		const message = (e as Error).message || 'Unkown error';
		addError(message, 5);
		console.error(message);
	}
}

export interface IProductsInfo {
	_id: string;
	name: string;
	image: string;
	rewards: IRewards[];
	caption: string;
	hashtags: string[];
}
interface IProductsList {
	dynamics: IProductsInfo[];
}
// Get all products listing ✅
export async function getAllProducts(): Promise<IProductsList | undefined> {
	try {
		const { APP } = useAppStore.getState();
		const headers = new Headers();
		headers.append('agencyKey', APP.User?.AgencyKey[0] || '');
		headers.append('at_token', APP.Auth?.AccessToken || '');

		const res = await fetch(
			`${process.env.REACT_APP_ADMIN_URL}/secure/dynamic/item`,
			{
				method: 'GET',
				headers,
			},
		);
		const jsonResp: unknown = await res.json();
		if (!jsonResp || typeof jsonResp !== 'object')
			throw Error('Server error. Not an object. ⛔️');
		if (
			res.status !== 200 &&
			'message' in jsonResp &&
			typeof jsonResp.message === 'string'
		)
			throw Error(jsonResp.message);
		if (
			!('data' in jsonResp) ||
			typeof jsonResp.data !== 'object' ||
			!jsonResp.data
		)
			throw Error('Server error. ⛔️');

		return jsonResp.data as IProductsList;
	} catch (e) {
		const message = (e as Error).message || 'Unkown error';
		addError(message, 5);
		console.error(message);
	}
}

interface IVideoInfo {
	_id: string;
	type: string;
	userId: string;
	uploadProgress: number;
	activeUsability: boolean;
	createdAt: string;
	updatedAt: string;
	__v: number;
	url: string;
}
// get the information for the video ✅
export async function getVideoInfo(
	id: string,
): Promise<IVideoInfo | undefined> {
	try {
		const { APP } = useAppStore.getState();
		const headers = new Headers();
		headers.append('agencyKey', APP.User?.AgencyKey[0] || '');
		headers.append('at_token', APP.Auth?.AccessToken || '');

		const res = await fetch(
			`${process.env.REACT_APP_ADMIN_URL}/secure/upload/${id}`,
			{
				method: 'GET',
				headers,
			},
		);
		const jsonResp: unknown = await res.json();
		if (!jsonResp || typeof jsonResp !== 'object')
			throw Error('Server error. Not an object. ⛔️');
		if (
			res.status !== 200 &&
			'message' in jsonResp &&
			typeof jsonResp.message === 'string'
		)
			throw Error(jsonResp.message);
		if (
			!('data' in jsonResp) ||
			typeof jsonResp.data !== 'object' ||
			!jsonResp.data
		)
			throw Error('Server error. ⛔️');

		return jsonResp.data as IVideoInfo;
	} catch (e) {
		const message = (e as Error).message || 'Unkown error';
		addError(message, 5);
		console.error(message);
	}
}

export enum IProductStatus {
	Declined = 'Declined',
	Approved = 'Approved',
	Submitted = 'Submitted',
}
export interface IUpdatePostParams {
	postId: string;
	couponCode?: string;
	status: IProductStatus;
}
// update post status to approve or decline ✅
export async function updatePostStatus(params: IUpdatePostParams) {
	try {
		const { APP } = useAppStore.getState();
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('at_token', APP.Auth?.AccessToken || '');

		const res = await fetch(
			`${process.env.REACT_APP_ADMIN_URL}/secure/approve-post`,
			{
				method: 'PUT',
				headers,
				body: JSON.stringify(params),
			},
		);
		const jsonResp: unknown = await res.json();
		if (!jsonResp || typeof jsonResp !== 'object')
			throw Error('Server error. Not an object. ⛔️');
		if (
			res.status !== 200 &&
			'message' in jsonResp &&
			typeof jsonResp.message === 'string'
		)
			throw Error(jsonResp.message);
		if (
			!('data' in jsonResp) ||
			typeof jsonResp.data !== 'object' ||
			!jsonResp.data
		)
			throw Error('Server error. ⛔️');
	} catch (e) {
		const message = (e as Error).message || 'Unkown error';
		addError(message, 5);
		console.error(message);
	}
}

export interface IUserInfo {
	_id: string;
	username: string;
	followers_count: number;
	name: string;
	profile_picture_url: string;
}
// get user information
export async function getUserInfo(id: string) {
	try {
		const { APP } = useAppStore.getState();
		const headers = new Headers();
		headers.append('at_token', APP.Auth?.AccessToken || '');

		const res = await fetch(
			`${process.env.REACT_APP_ADMIN_URL}/secure/user/${id}`,
			{
				method: 'GET',
				headers,
			},
		);
		const jsonResp: unknown = await res.json();
		if (!jsonResp || typeof jsonResp !== 'object')
			throw Error('Server error. Not an object. ⛔️');
		if (
			res.status !== 200 &&
			'message' in jsonResp &&
			typeof jsonResp.message === 'string'
		)
			throw Error(jsonResp.message);
		if (
			!('data' in jsonResp) ||
			typeof jsonResp.data !== 'object' ||
			!jsonResp.data
		)
			throw Error('Server error. ⛔️');

		return jsonResp.data as IUserInfo;
	} catch (e) {
		const message = (e as Error).message || 'Unkown error';
		addError(message, 5);
		console.error(message);
	}
}
