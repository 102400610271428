import { PropsWithChildren } from 'react';
import { Outlet } from 'react-router';
import Notices from '../../Notices';
import { useNoticeStore } from '../../../stores/notice';

function Main(props: PropsWithChildren) {
	const { children } = props;
	const { notices } = useNoticeStore(state => state);

	return (
		<>
			<Notices notices={notices} />
			<div className="d-flex flex-column vh-100">
				<div className="bg-black h-100 overflow-auto d-flex">
					{children}
					<Outlet />
				</div>
			</div>
		</>
	);
}

export default Main;
