import { useSpring, animated } from '@react-spring/web';
import { ReactNode, useEffect } from 'react';

interface IModalProps {
	title?: string;
	Footer?: ReactNode;
	Body?: ReactNode;
	handleClose?: (params?: any) => void;
	showBackdrop?: boolean;
	open?: boolean;
	size?: 'sm' | 'lg' | '';
}
function Modal(props: IModalProps) {
	const {
		title,
		Footer,
		Body,
		handleClose,
		showBackdrop = true,
		open = false,
		size = '',
	} = props;

	const [springs, api] = useSpring(() => ({
		from: { opacity: 0 },
	}));

	function onCloseModal() {
		api.start({
			from: { opacity: 1 },
			to: { opacity: 0 },

			onRest: () => {
				handleClose && handleClose();
			},
		});
	}

	useEffect(() => {
		if (open) {
			api.start({
				from: { opacity: 0 },
				to: { opacity: 1 },
			});
		}
	}, [open, api]);

	if (!open) return null;

	return (
		<>
			<animated.div
				style={{ ...springs }}
				className="modal d-block"
				id="sampleModal"
				tabIndex={-1}
				role="dialog"
				onClick={onCloseModal}
			>
				<div
					className={`modal-dialog modal-dialog-centered modal-${size}`}
					role="document"
				>
					<div className="modal-content">
						<div className="modal-header d-flex justify-content-between">
							<h4 className="modal-title fw-bold">{title}</h4>
							<button
								type="button"
								className="close btn"
								data-dismiss="modal"
								aria-label="Close"
							>
								❌
							</button>
						</div>
						<div className="modal-body">{Body}</div>
						<div className="modal-footer">{Footer}</div>
					</div>
				</div>
			</animated.div>
			{showBackdrop && <div className="modal-backdrop show" />}
		</>
	);
}

export default Modal;
