/**
 * Takes key value pairs in the form of {key_in_app: key_in_core} and returns an object with the values in the form the app needs them
 * @param {Object} obj as provided by Core
 * @param {Record<string,string|function>} map in the form of {key_in_app: key_in_core} key_in_core can be a (item)=> some processing
 * @returns {Object} in the form of {key_in_app: value}
 */
export function serializeResponse<J extends object, T extends object>(
	obj: T,
	map: { [P in keyof J]: Extract<keyof T, string> | ((obj: T) => any) },
): J {
	const res = {};
	for (const key_in_app in map) {
		const key_in_core = map[key_in_app];
		if (typeof key_in_core === 'string') {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore it could be any
			res[key_in_app] = obj[key_in_core];
		} else if (key_in_core instanceof Function) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore it could be any
			res[key_in_app] = key_in_core(obj);
		} else {
			throw new Error(`invalid map for key '${key_in_core}'`);
		}
	}
	return res as J;
}

/* 
Get instagram oauth url
*/
export function getInstagramAuthUri() {
	const permissionsScope = [
		'instagram_business_basic',
		// for insta story
		'instagram_business_content_publish',
	];
	// TODO change redirect url to make this dynamic
	const redirectUrl = window.location.origin + '/oauth/final';

	const url = `${process.env.REACT_APP_INSTAGRAM_OUTH_URL}?client_id=${process.env.REACT_APP_CLIENT_ID || ''}&redirect_uri=${redirectUrl}&scope=${permissionsScope.join(',')}&enable_fb_login=0&force_authentication=1&response_type=code`;
	return url;
}

/*
Get redirect oauth final url
*/
export function getOauthRedirectUrl() {
	return window.location.origin + '/oauth/final';
}
