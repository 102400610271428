import { RefObject, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { Link, useLocation } from 'react-router-dom';
import LogoutModal from '../Modal/Logout';
import { useAppStore } from '../../stores/appMain';

function SidebarTabs() {
	const location = useLocation();
	const { APP } = useAppStore(state => state);
	const [sidebarTabs, setSidebarTabs] = useState([
		{
			path: '/',
			title: 'Home',
			icon: 'house',
		},
	]);

	useEffect(() => {
		if (!APP.User?.Blocked) {
			setSidebarTabs(prev => [
				...prev,
				{
					path: '/submissions',
					title: 'Submissions',
					icon: 'postcard',
				},
				{
					path: '/products-list',
					title: 'Products',
					icon: 'x-diamond',
				},
			]);
		}
	}, [APP.User?.Blocked]);

	return (
		<>
			{sidebarTabs.map((tab, index) => {
				return (
					<Link
						to={tab.path}
						key={index}
						className={`mt-2 p-2 px-3
							${location.pathname === tab.path ? 'bg-black text-white rounded-start-5 me-0 mx-3' : ''}
						`}
					>
						<i
							className={`bi bi-${tab.icon}${location.pathname === tab.path ? '-fill' : ''} fs-4`}
						></i>
						<span className="mx-2">{tab.title}</span>
					</Link>
				);
			})}
		</>
	);
}

function Sidebar() {
	const [isSidebarOpen, setIsSidebarOpen] = useState(true);
	const sidebarRef: RefObject<HTMLDivElement> = useRef(null);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	function handleToggleNav() {
		setIsSidebarOpen(prev => !prev);
	}

	return (
		<div
			ref={sidebarRef}
			className={`d-flex flex-column justify-content-between bg-white ${styles.sidebar} ${isSidebarOpen ? styles.open : styles.closed}`}
		>
			<div>
				<div className={styles.sidebarHeader}>
					<h3>Menu</h3>
					<button
						className={styles.toggleCta}
						onClick={handleToggleNav}
					>
						<i
							className={`bi bi-${isSidebarOpen ? 'arrow-bar-left' : 'list'} fs-4`}
						></i>
					</button>
				</div>
				<SidebarTabs />
			</div>
			<button
				className="btn text-start p-3 px-3 d-flex align-items-center"
				onClick={() => setShowConfirmationModal(true)}
			>
				<i className="bi bi-box-arrow-right me-2 fs-4" />
				{isSidebarOpen && <span className="mx-2">Logout</span>}
			</button>
			<LogoutModal
				isModalOpen={showConfirmationModal}
				closeModal={() => setShowConfirmationModal(false)}
			/>
		</div>
	);
}

export default Sidebar;
