import { useMemo } from 'react';
import Modal from '..';
import { useAppStore } from '../../../stores/appMain';

interface ILogoutModalProps {
	isModalOpen: boolean;
	closeModal: () => void;
}
function LogoutModal(props: ILogoutModalProps) {
	const { isModalOpen, closeModal } = props;
	const { set } = useAppStore(state => state);

	const renderModalFooter = useMemo(
		function renderModalFooter() {
			function handleSubmit() {
				closeModal();
				set({
					User: undefined,
					Auth: undefined,
				});
				window.location.href = '/login';
			}

			return (
				<div className="d-flex justify-content-end m-2">
					<button
						onClick={handleSubmit}
						type="submit"
						className="rounded-pill d-flex align-items-center btn bg-primary d-flex items-center justify-content-center gap-2 p-2 px-3 text-white rounded"
					>
						<p className="m-0">I Understand</p>
					</button>
				</div>
			);
		},
		[set, closeModal],
	);

	const renderModalBody = useMemo(function renderModalBody() {
		return (
			<p className="text-black">
				This action will logout and you will need to login again.
			</p>
		);
	}, []);
	return (
		<Modal
			open={isModalOpen}
			handleClose={closeModal}
			title="Confirm"
			Footer={renderModalFooter}
			Body={renderModalBody}
		/>
	);
}

export default LogoutModal;
