import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { useAppStore } from '../../../stores/appMain';

function Secure(props: PropsWithChildren) {
	const { children } = props;
	const { APP } = useAppStore(state => state);

	if (!APP.User?.ID) {
		return (
			<div className="d-flex align-items-center justify-content-center bg-black vh-100">
				<p>
					Unauthorized user. Please <Link to="login">Login</Link>
				</p>
			</div>
		);
	}

	return <>{children}</>;
}

export default Secure;
