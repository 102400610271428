import { useRouteError, isRouteErrorResponse } from 'react-router-dom';

function ErrorElement() {
	const error = useRouteError();
	let errorMessage: string;

	if (isRouteErrorResponse(error)) {
		errorMessage = error.statusText;
	} else if (error instanceof Error) {
		errorMessage = error.message;
	} else if (typeof error === 'string') {
		errorMessage = error;
	} else {
		errorMessage = 'Unknown error';
	}
	console.error(error);

	return (
		<div
			id="error-page"
			className="d-flex align-items-center justify-content-center flex-column h-100"
		>
			<h1 className="text-white">❗️ Oops ❗️</h1>
			<p>Sorry, an unexpected error has occurred.</p>
			<p className="text-danger-emphasis">
				<i>{errorMessage}</i>
			</p>
		</div>
	);
}

export default ErrorElement;
