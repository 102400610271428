interface IHeaderProps {
	text: string;
	classes: string;
}

function Header(props: IHeaderProps) {
	const { text, classes } = props;
	return <p className={`${classes} fs-2 fw-bold`}>{text}</p>;
}

export default Header;
