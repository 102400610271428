function PageNotFound() {
	return (
		<div className="d-flex align-items-center justify-content-center h-100 w-100 flex-column">
			<p>Page Not Found. ⚠️</p>
			<p>
				Try <a href="/login">Login</a>
			</p>
		</div>
	);
}

export default PageNotFound;
