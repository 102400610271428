import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.scss';
import Sidebar from './lib/components/Sidebar';
import Home from './pages/home';
import Submissions from './pages/submissions';
import Public from './lib/components/Layout/Public';
import Login from './pages/login';
import Main from './lib/components/Layout/Main';
import Oauth from './pages/oauth';
import Auth from './pages/oauth/auth';
import Final from './pages/oauth/final';
import PageNotFound from './pages/wip';
import Secure from './lib/components/Layout/Secure';
import ProductsList from './pages/products-list';
import ErrorElement from './lib/components/ErrorElement';

const router = createBrowserRouter([
	{
		path: '/',
		errorElement: <ErrorElement />,
		element: (
			<Secure>
				<Main>
					<Sidebar />
				</Main>
			</Secure>
		),
		children: [
			{
				index: true,
				element: <Home />,
			},
			{
				path: 'submissions',
				element: <Submissions />,
			},
			{
				path: 'products-list',
				element: <ProductsList />,
			},
		],
	},
	{
		path: '/login',
		errorElement: <ErrorElement />,
		element: (
			<Main>
				<Login />
			</Main>
		),
	},
	{
		path: '/oauth',
		errorElement: <ErrorElement />,
		element: (
			<Public>
				<Main />
			</Public>
		),
		children: [
			{
				index: true,
				element: <Oauth />,
			},
			{
				path: 'auth',
				element: <Auth />,
			},
			{
				path: 'final',
				element: <Final />,
			},
		],
	},
	{
		path: '*',
		errorElement: <ErrorElement />,
		element: (
			<Public>
				<Main>
					<PageNotFound />
				</Main>
			</Public>
		),
	},
]);

function App() {
	return <RouterProvider router={router} />;
}

export default App;
